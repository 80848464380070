import { IPopup, IPopupRequest } from '@/utils/types'
import { NormApi } from './norm'

export class PopupApi extends NormApi<IPopup> {
  list = async (params: IPopupRequest) => {
    const response = await this.axios.get('/v1/popup/list', {
      params,
    })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/popup/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IPopup) => {
    const response = await this.axios.post('/v1/popup/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IPopup) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/popup/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/popup/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: IPopup) => {
    const response = await this.axios.post(`/v1/popup/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: IPopup) => {
    const response = await this.axios.post(`/v1/popup/${payload._id}/test`, payload)
    return this.responseHandler(response)
  }
}

const popupApi = new PopupApi()
export default popupApi

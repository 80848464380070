import { ACTION_STORY } from './actions'
import { ISendingTestRequest, IStory, IStoryRequest } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import storyApi from '@/api/story'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IStory[]
  item: IStory
  loading: boolean
}

export default {
  state: {
    items: [],
    item: {},
    loading: false,
  },
  getters: {
    stories: (state: TState) => {
      return state.items as IStory[]
    },
    story: (state: TState) => {
      return state.item as IStory
    },
  },
  mutations: {
    // [List]
    [ACTION_STORY.SET_ITEMS]: (state: TState, { items }: { items: IStory[] }) => {
      state.items = cloneDeep(items)
    },
    // [Read item]
    [ACTION_STORY.SET_ITEM]: (state: TState, { item }: { item: IStory }) => {
      state.item = cloneDeep(item)
    },
    // [New item]
    [ACTION_STORY.SET_NEW_ITEM]: (state: TState, { item }: { item: IStory }) => {
      state.items = apiListChangedHandler<IStory>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_STORY.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IStory }) => {
      state.items = apiListChangedHandler<IStory>(state.items, cloneDeep(item), 'modified')
    },
    // [Deleted item]
    [ACTION_STORY.SET_DELETED_ITEM]: (state: TState, { item }: { item: IStory }) => {
      state.items = apiListChangedHandler<IStory>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_STORY.LOAD_ITEMS]: async ({ commit }, params: IStoryRequest) => {
      const key = constant.LOCAL_KEYS.STORIES + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await storyApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_STORY.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_STORY.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await storyApi.single(id)
      commit(ACTION_STORY.SET_ITEM, { item: resp })
      return resp
    },
    [ACTION_STORY.ADD]: async ({ commit }, payload: IStory) => {
      const resp = await storyApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.STORIES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        commit(ACTION_STORY.SET_NEW_ITEM, { item: resp })
        return resp
      }
    },
    [ACTION_STORY.UPDATE]: async ({ commit }, payload: IStory) => {
      const resp = await storyApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.STORIES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        commit(ACTION_STORY.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
    },
    [ACTION_STORY.DELETE]: async ({ commit }, id: string) => {
      const resp = await storyApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.STORIES + resp.app_id)
        commit(ACTION_STORY.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_STORY.IS_ACTIVE]: async ({ commit }, payload: IStory) => {
      const resp = await storyApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.STORIES + payload.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + payload.app_id)
        commit(ACTION_STORY.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    [ACTION_STORY.TEST_MODE]: async ({ commit }, payload: IStory) => {
      const resp = await storyApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.STORIES + payload.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + payload.app_id)
        commit(ACTION_STORY.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    [ACTION_STORY.SENDING_TEST]: async ({ commit }, payload: ISendingTestRequest) => {
      const resp = await storyApi.sendingTest(payload)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_STORY.REPORT]: async ({ commit }, params: IStory) => {
      // const key = constant.LOCAL_KEYS.STORIES_REPORT + params.app_id
      // let resp
      // resp = localStore.getWithExpiry(key)
      // if (!resp) {
      //   resp = await storyApi.storyReport(params)
      //   localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      // }
      const resp = await storyApi.storyReport(params)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_STORY.VALIDATE_DEFAULT_TYPE]: async ({ commit }, payload: IStory) => {
      const resp = await storyApi.validateDefaultType(payload)
      return resp
    },
},
}

import { ACTION_CAMPAIGN } from './actions'
import { ICampaign, ICampaignRequest } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import campaignApi from '@/api/campaign'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: ICampaign[]
  loading: boolean
  tagjs_url: string
  success_tagjs_url: string
}

export default {
  state: {
    items: [],
    loading: false,
    tagjs_url: '',
    success_tagjs_url: '',
  },
  getters: {
    campaigns: (state: TState) => {
      return state.items as ICampaign[]
    },
    tagjsUrl: (state: TState) => {
      return state.tagjs_url
    },
    successTagjsUrl: (state: TState) => {
      return state.success_tagjs_url
    },
  },
  mutations: {
    // [List]
    [ACTION_CAMPAIGN.SET_ITEMS]: (state: TState, { items }: { items: ICampaign[] }) => {
      state.items = cloneDeep(items)
    },

    // [New item]
    [ACTION_CAMPAIGN.SET_NEW_ITEM]: (state: TState, { item }: { item: ICampaign }) => {
      state.items = apiListChangedHandler<ICampaign>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_CAMPAIGN.SET_UPDATED_ITEM]: (state: TState, { item }: { item: ICampaign }) => {
      state.items = apiListChangedHandler<ICampaign>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_CAMPAIGN.SET_DELETED_ITEM]: (state: TState, { item }: { item: ICampaign }) => {
      state.items = apiListChangedHandler<ICampaign>(state.items, cloneDeep(item), 'removed')
    },
    [ACTION_CAMPAIGN.SET_TAG_JS]: (state: TState, { item }) => {
      state.tagjs_url = item.tagjs_url
      state.success_tagjs_url = item.success_tagjs_url
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_CAMPAIGN.LOAD_ITEMS]: async ({ commit }, params: ICampaignRequest) => {
      const key = constant.LOCAL_KEYS.CAMPAIGNS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await campaignApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_CAMPAIGN.SET_ITEMS, { items: resp })
      return resp
    },

      // eslint-disable-next-line
      [ACTION_CAMPAIGN.LOAD_COMPACT_ITEMS]: async ({ commit }, params: ICampaignRequest) => {
        const key = constant.LOCAL_KEYS.CAMPAIGNS_COMPACT + params.app_id + JSON.stringify(params)
        let resp
        resp = localStore.getWithExpiry(key)
        if (!resp) {
          resp = await campaignApi.list_compact(params)
          localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
        }

        return resp
      },

    // eslint-disable-next-line
    [ACTION_CAMPAIGN.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await campaignApi.single(id)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_CAMPAIGN.LOAD_STORY_ITEM]: async ({ commit }, id: string) => {
      const resp = await campaignApi.story_single(id)
      return resp
    },

    [ACTION_CAMPAIGN.ADD]: async ({ commit }, payload: ICampaign) => {
      const resp = await campaignApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS)
        commit(ACTION_CAMPAIGN.SET_NEW_ITEM, { item: resp })
        return resp
      }
    },
    [ACTION_CAMPAIGN.UPDATE]: async ({ commit }, payload: ICampaign) => {
      const resp = await campaignApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS)
        commit(ACTION_CAMPAIGN.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
    },
    [ACTION_CAMPAIGN.DELETE]: async ({ commit }, id: string) => {
      const resp = await campaignApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS)
        commit(ACTION_CAMPAIGN.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_CAMPAIGN.IS_ACTIVE]: async ({ commit }, payload: ICampaign) => {
      const resp = await campaignApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS)
        commit(ACTION_CAMPAIGN.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },

    [ACTION_CAMPAIGN.TEST_MODE]: async ({ commit }, payload: ICampaign) => {
      const resp = await campaignApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.CAMPAIGNS)
        commit(ACTION_CAMPAIGN.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },

    [ACTION_CAMPAIGN.LOADED_TAG_JS]: async ({ commit }) => {
      let resp
      resp = localStore.getWithExpiry(constant.LOCAL_KEYS.CAMPAIGN_TAGURL)
      if (!resp) {
        resp = await campaignApi.getCampaignTagJs()
        localStore.setWithExpiry(constant.LOCAL_KEYS.CAMPAIGN_TAGURL, resp, constant.LOCAL_EXPIRED)
        if (!resp) {
          return false
        }
      }
      commit(ACTION_CAMPAIGN.SET_TAG_JS, { item: resp })
      return true
    },
  },
}
